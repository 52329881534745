export default defineNuxtRouteMiddleware((to) => {
    const redirectPaths = new Set([
        '/amm',
        '/apcmed',
        '/audit',
        '/audit-2023',
        '/audit-geo',
        '/auditgeo',
        '/course',
        '/efir07',
        '/enjoy',
        '/eqiva',
        '/growthmed',
        '/growthmed24',
        '/mbs',
        '/med_marketing2023',
        '/med_marketing2024',
        '/medgl',
        '/muslim',
        '/progeo',
        '/uzhin',
        '/vademecum',
        '/vademecum_live',
        '/youtube',
        '/youtubea',
        '/zerts',
    ])

    if (redirectPaths.has(to.path)) {
        return navigateTo(`/events${to.path}`, { redirectCode: 301 })
    }
})
