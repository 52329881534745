export async function useChangeApi() {
  const { public: { API_SWITCHER, BASE_API_URL } } = useRuntimeConfig()
  const envApi = ref(API_SWITCHER ? useCookie('api').value || 'https://app.rangai.ru/api' : BASE_API_URL)
  const isDev = ref(envApi.value === 'https://dev.rangai.ru/api')

  const keyDownHandler = (e: KeyboardEvent): void => {
    if (e.ctrlKey && e.altKey && e.code === 'ArrowLeft') {
      isDev.value = !isDev.value
      alert(`Reloading with ${isDev.value ? 'DEV' : 'PROD'}`)
      reloadNuxtApp({
        ttl: 1000,
        force: true,
      })
    }
  }

  watch(isDev, () => isDev.value ? envApi.value = 'https://dev.rangai.ru/api' : envApi.value = 'https://app.rangai.ru/api')
  watch(envApi, () => useCookie('api').value = envApi.value)

  if (API_SWITCHER) {
    onMounted(() => {
      window.addEventListener('keydown', keyDownHandler)
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', keyDownHandler)
    })
  }

  return { envApi }
}
