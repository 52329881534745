import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAdviceSection, LazyAdviceText, LazyAllArticles, LazyArticle, LazyArticleSection, LazyBaseImage, LazyBaseSection, LazyBaseText, LazyColumnsCard, LazyDropDown, LazyEmbedSheets, LazyEmbedVideo, LazyEmbedVideoVk, LazyFeature, LazyGrid, LazyPage, LazyReview, LazyReviewGrid, LazyReviewVideo, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AdviceSection", LazyAdviceSection],
["AdviceText", LazyAdviceText],
["AllArticles", LazyAllArticles],
["Article", LazyArticle],
["ArticleSection", LazyArticleSection],
["BaseImage", LazyBaseImage],
["BaseSection", LazyBaseSection],
["BaseText", LazyBaseText],
["ColumnsCard", LazyColumnsCard],
["DropDown", LazyDropDown],
["EmbedSheets", LazyEmbedSheets],
["EmbedVideo", LazyEmbedVideo],
["EmbedVideoVk", LazyEmbedVideoVk],
["Feature", LazyFeature],
["Grid", LazyGrid],
["Page", LazyPage],
["Review", LazyReview],
["ReviewGrid", LazyReviewGrid],
["ReviewVideo", LazyReviewVideo],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
