import sbjs from 'sourcebuster'

export default defineNuxtPlugin(({ vueApp }) => {
  sbjs.init()

  // fix page link
  vueApp.$nuxt.hook('page:finish', () => {
    const { BASE_URL } = vueApp.$nuxt.$config.public
    const sbjs_session: Ref<string> = useCookie('sbjs_session', {
      domain: `.${BASE_URL.slice(8, BASE_URL.length - 1)}`,
    })

    const splittedSession = sbjs_session.value.split('|||')
    splittedSession[0] = `pgs=${Number(splittedSession[0].slice(4, splittedSession[0].length)) + 1}`
    splittedSession[1] = `cpg=${BASE_URL.slice(0, BASE_URL.length - 1)}${vueApp.$nuxt.$router.currentRoute.value.fullPath}`
    sbjs_session.value = splittedSession.join('|||')
  })

  return {
    provide: {
      sbjs,
    },
  }
})
