import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/app/middleware/redirect-trailing-slash.global.js";
import router_45global from "/app/middleware/router.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  router_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}