<script setup>
const path = useRoute().path
const { public: { BASE_URL } } = useRuntimeConfig()

useChangeApi()

useSeoMeta({
  ogType: 'website',
  ogLocale: 'ru-RU',
})

useSchemaOrg([
  defineOrganization({
    name: 'Rang.ai',
    description: 'Сервис для продвижения в картах',
    logo: '~/assets/i/sprite/sprite.svg#logo',
    email: 'art@rang.ai',
    address: {
      '@type': 'PostalAddress',
      'addressCountry': 'RU',
    },
    url: 'https://rang.ai',
    sameAs: ['https://t.me/ideasclinic', 'https://www.youtube.com/@ideasclinic', 'https://app.rangai.ru'],
  }),
])

useHead({
  htmlAttrs: { lang: 'ru' },
  meta: [
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-title', content: 'Главная' },
    { name: 'color-scheme', content: 'light only' },
  ],
  link: [
    {
      rel: 'canonical',
      href: `${BASE_URL}${path.substring(1, path.length)}`,
    },
  ],
})
</script>

<template>
  <NuxtLoadingIndicator color="#0b74b0" error-color="red" :height="5" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="postcss">
body, #__nuxt, html {
  min-height: 100vh;
  scroll-behavior: smooth;
  background: #fff;
}
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.2s;
}
.slide-left-enter-from {
  opacity: 0;
  transform: translate(50px, 0);
}
.slide-left-leave-to {
  opacity: 0;
  transform: translate(-50px, 0);
}
.slide-right-enter-from {
  opacity: 0;
  transform: translate(-50px, 0);
}
.slide-right-leave-to {
  opacity: 0;
  transform: translate(50px, 0);
}
.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}
</style>
